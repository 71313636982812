import { render, staticRenderFns } from "./cooperation.vue?vue&type=template&id=0ece8589&scoped=true"
import script from "./cooperation.vue?vue&type=script&lang=js"
export * from "./cooperation.vue?vue&type=script&lang=js"
import style0 from "./cooperation.vue?vue&type=style&index=0&id=0ece8589&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ece8589",
  null
  
)

export default component.exports